import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FaqAll from "../components/faq-all"
import ContactUs from "../components/contact-us"
import HeaderAbout from "../components/header-about"

const AboutUsPage = ({ pageContext: { locale, keywords } }) => (
  <Layout locale={locale}>
    <HeaderAbout />
    <SEO
      title="FAQ"
      keywords={[
        ...keywords,
        `MASS Blockchain Consensus Engine`,
        `MASS 常见问题`,
        `MASS`,
      ]}
      lang={locale}
    />
    <FaqAll />
    <ContactUs />
  </Layout>
)

AboutUsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    keywords: PropTypes.array.isRequired,
  }).isRequired,
}

export default AboutUsPage
