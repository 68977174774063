import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"

import "./contact-us.css"

const ContactUs = () => (
  <Fragment>
    <div className="container">
      <h1 className="contact-us-title">
        <span>
          <FormattedMessage id="org.massnet.components.about_us.contact" />
        </span>
      </h1>
      <div className="contact-us-content">
        <img src={require('../images/contactus.png')} alt="MASS,区块链共识引擎,POC,存储空间租赁,容量证明,共识引擎,挖矿" style={{width: '50px', height: '50px'}}/>
        <p>
          <FormattedMessage id="org.massnet.components.contact_us.address" />
        </p>
      </div>
    </div>
  </Fragment>
)

export default ContactUs
